<template>
  <div class="nosotros-container">
    <app-header rootClassName="header-root-class-name2"></app-header>
    <div class="nosotros-banner">
      <div class="nosotros-ourHistory-banner">
        <div class="nosotros-ourHistory-group">
          <div class="nosotros-ourHistory-content">
            <span class="nosotros-ourHistory-title HeadingH3">
              <span>{{ $t('about.ourTitle') }}</span>
            </span>
            <span class="nosotros-ourHistory-text LeadLead1">
              <span>{{ $t('about.ourDescription') }}</span>
            </span>
          </div>
        </div>
        <div class="nosotros-phone-mockup">
          <div class="nosotros-mobilei-phone-x">
            <img alt="iPhoneXI407" src="/external/19.jpeg" class="nosotros-i-phone-x" />
          </div>
        </div>
      </div>
    </div>
    <div class="nosotros-goals-banner">
      <div class="nosotros-goal-group">
        <div class="nosotros-goal-container">
          <img alt="image" src="/external/20.jpeg" class="nosotros-goal-image" />
          <div class="nosotros-goal-card">
            <h2 class="nosotros-goal-title HeadingH3">{{ $t('about.mission') }}</h2>
            <span class="nosotros-goal-text LeadLead1">{{ $t('about.missionDesc') }}</span>
          </div>
        </div>
      </div>
      <div class="nosotros-goal-group">
        <div class="nosotros-goal-container">
          <div class="nosotros-goal-card">
            <h2 class="nosotros-goal-title HeadingH3">{{ $t('about.vision') }}</h2>
            <span class="nosotros-goal-text LeadLead1">{{ $t('about.visionDesc') }}</span>
          </div>
          <img alt="image" src="/external/21.jpg" class="nosotros-goal-image" />
        </div>
      </div>
    </div>
    <div class="nosotros-values-banner">
      <div class="nosotros-values-group">
        <div class="nosotros-values-container">
          <h1 class="nosotros-values-title HeadingH3">{{ $t('headers.corporate-values.title') }}</h1>
          <span class="nosotros-values-text LeadLead1"> {{ $t('headers.corporate-values.description') }}</span>
        </div>
        <div class="nosotros-steps-container">
          <div class="nosotros-step-card">
            <div class="nosotros-step-image">
              <div class="nosotros-step-hidden-line"></div>
              <div class="nosotros-step-img">
                    <i class="fas fa-handshake col-ico"></i>
              </div>
              <div class="nosotros-step-broken-line"></div>
            </div>
            <div class="nosotros-step-description">
              <h1 class="nosotros-step-title">{{ $t('corporate-values.value-one.valueTitle') }}</h1>
              <span class="nosotros-step-text">{{ $t('corporate-values.value-one.valueDesc') }}</span>
            </div>
          </div>
          <div class="nosotros-step-card">
            <div class="nosotros-step-image">
              <div class="nosotros-step-broken-line"></div>
              <div class="nosotros-step-img">
                <i class="fas fa-check col-ico"></i>
              </div>
              <div class="nosotros-step-broken-line"></div>
            </div>
            <div class="nosotros-step-description">
              <h1 class="nosotros-step-title">{{ $t('corporate-values.value-four.valueTitle') }}</h1>
              <span class="nosotros-step-text">{{ $t('corporate-values.value-four.valueDesc') }}</span>
            </div>
          </div>
          <div class="nosotros-step-card">
            <div class="nosotros-step-image">
              <div class="nosotros-step-broken-line"></div>
              <div class="nosotros-step-img">
                <i class="fas fa-briefcase col-ico"></i>
              </div>
              <div class="nosotros-step-broken-line"></div>
            </div>
            <div class="nosotros-step-description">
              <h1 class="nosotros-step-title">{{ $t('corporate-values.value-three.valueTitle') }}</h1>
              <span class="nosotros-step-text">{{ $t('corporate-values.value-three.valueDesc') }}</span>
            </div>
          </div>
          <div class="nosotros-step-card">
            <div class="nosotros-step-image">
              <div class="nosotros-step-broken-line"></div>
              <div class="nosotros-step-img">
                <i class="fas fa-users col-ico"></i>
              </div>
              <div class="nosotros-step-hidden-line"></div>
            </div>
            <div class="nosotros-step-description">
              <h1 class="nosotros-step-title">{{ $t('corporate-values.value-two.valueTitle') }}</h1>
              <span class="nosotros-step-text">{{ $t('corporate-values.value-two.valueDesc') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
    <div class="nosotros-policy">
      <div class="nosotros-container-policy">
        <div class="nosotros-container-title-policy">
          <h1 class="nosotros-text-policy HeadingH3">{{ $t('headers.policies.title') }}</h1>
        </div>
      </div>
      <div class="nosotros-policy-items">
        <div class="nosotros-vertical-group">
          <div class="nosotros-vertical-item">
            <PoliceCard
                :title='$t("policies.policy-one.valueTitle")'
                :description='$t("policies.policy-one.valueDesc")'
                rootClassName="rootClassName"></PoliceCard>
          </div>
        </div>
      <div class="nosotros-horizontal-group">
        <div class="nosotros-horizontal-item">
            <PoliceCard
                :title='$t("policies.policy-two.valueTitle")'
                :description='$t("policies.policy-two.valueDesc")'>
            </PoliceCard>
            <PoliceCard
                :title='$t("policies.policy-three.valueTitle")'
                :description='$t("policies.policy-three.valueDesc")'>
            </PoliceCard>
        </div>
      </div>
    </div>
    </div>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'
import PoliceCard from "@/components/police-card.vue";

export default {
  name: 'Nosotros',
  components: {
    AppHeader,
    PoliceCard,
    AppFooter,
  },
  metaInfo: {
    title: 'Nosotros - AcsEnergy',
    meta: [
      {
        property: 'og:title',
        content: 'Nosotros - AcsEnergy',
      },
    ],
  },
}
</script>

<style scoped>
.nosotros-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-banner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-logo-blanco-03);
}
.nosotros-ourHistory-banner {
  gap: 30px;
  width: 100%;
  height: 750px;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}
.nosotros-ourHistory-group {
  gap: 10px;
  width: 80%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color : var(--dl-color-logo-02);
}
.nosotros-ourHistory-content {
  gap: 32px;
  display: flex;
  padding: 48px 32px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}
.nosotros-ourHistory-title {
  color: var(--dl-color-default-white);
  width: 100%;
  height: auto;
  text-align: center;
  line-height: 54px;
}
.nosotros-ourHistory-text {
  color: var(--dl-color-text-gray7);
  width: 100%;
  height: auto;
  text-align: justify;
  line-height: 32px;
}
.nosotros-phone-mockup {
  top: 0;
  right: 0;
  width: auto;
  bottom: 0;
  height: auto;
  display: flex;
  position: absolute;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-shrink: 0;
  margin-right: 220px;
  flex-direction: column;
}
.nosotros-mobilei-phone-x {
  width: 420px;
  height: 701px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.nosotros-i-phone-x {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 64px;
}

.nosotros-goals-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.nosotros-goal-group {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nosotros-goal-container {
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.nosotros-goal-image {
  width: 450px;
  height: 450px;
  object-fit: cover;
  flex-shrink: 0;
  margin: auto;
  border-radius: var(--dl-radius-radius-round);

}
.nosotros-goal-card {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
.nosotros-goal-title {
  font-weight: 600;
  width: 100%;
  height: auto;
  line-height: 54px;
}
.nosotros-goal-text {
  color: var(--dl-color-gray-700);
  text-align: justify;
  line-height: 32px;
}
.nosotros-values-banner {
  color: var(--dl-color-default-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 100%;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color : var(--dl-color-logo-02);
}
.nosotros-values-group {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nosotros-values-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--dl-space-space-threeunits);
  gap: 32px;
}
.nosotros-values-title {
  color: var(--dl-color-default-white);
  font-size: 3rem;
}
.nosotros-values-text {
  color: var(--dl-color-default-white);
  font-size: 1.15rem;
  max-width: 600px;
  text-align: justify;
}
.nosotros-steps-container {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.nosotros-step-card {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.nosotros-step-image {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.nosotros-step-hidden-line {
  flex: 1;
  height: 0;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.nosotros-step-img {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.nosotros-step-broken-line {
  flex: 1;
  height: 0;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-default-white);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.nosotros-step-description {
  display: flex;
  align-items: center;
  padding: var(--dl-space-space-unit);
  flex-direction: column;
  gap: 32px;
}
.nosotros-step-title{
  font-size: 1.7rem;
  text-align: center;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-unit);
}
.nosotros-step-text {
  color: var(--dl-color-default-white);
  font-size: 1.2rem;
  text-align: justify;
}
.col-ico{
  color: var(--dl-color-logo-02);
}

.nosotros-policy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nosotros-container-policy {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 100%;
  align-items: center;
  justify-content: center;
}
.nosotros-container-title-policy {
  flex: 1;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  text-align: center;
}

.nosotros-policy-items {
  width: 90%;
  height: auto;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-auto-rows: minmax(100px, auto);
}
.nosotros-vertical-group {
  width: 100%;
  grid-column: 1;
}
.nosotros-vertical-item {
  flex: 2;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.nosotros-horizontal-group{
  width: 100%;
  grid-column: 2 / 4;
}
.nosotros-horizontal-item{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.nosotros-text-policy {
  font-size: 3rem;
  text-align: center;
}

@media(max-width: 1440px) {
  .nosotros-phone-mockup {
    margin-right: 210px;
  }
}
@media(max-width: 1366px){

}
@media(max-width: 1280px) {
  .nosotros-ourHistory-banner {
    height: 700px;
  }
  .nosotros-phone-mockup {
    margin-right: 200px;
  }
  .nosotros-mobilei-phone-x {
    width: 370px;
    height: 650px;
  }
}
@media(max-width: 991px) {
  .nosotros-ourHistory-banner {
    height: 650px;
  }
  .nosotros-ourHistory-group {
    width: 90%;
  }
  .nosotros-phone-mockup {
    margin-right: 100px;
  }
  .nosotros-mobilei-phone-x {
    width: 320px;
    height: 600px;
  }
  .nosotros-goal-image {
    width: 350px;
    height: 350px;
  }
  .nosotros-values-title {
    text-align: center;
  }
  .nosotros-values-text {
    text-align: justify;
  }
  .nosotros-step-title{
    font-size: 1.5rem;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .nosotros-step-text {
    font-size: 1rem;
  }
  .nosotros-container-policy {
    align-items: center;
    flex-direction: column;
  }
  .nosotros-container-title-policy {
    align-items: center;
  }
  .nosotros-policy-items {
    width: 100%;
  }
  .nosotros-vertical-group {
    grid-column: 1 / 4;
  }
  .nosotros-vertical-item {
    margin-right: 0;
  }
  .nosotros-horizontal-group {
    grid-column: 1 / 4;
  }

}
@media(max-width: 720px) {
  .nosotros-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-ourHistory-group {
    width: 100%;
    align-items: center;
  }
  .nosotros-ourHistory-content {
    width: 100%;
  }
  .nosotros-ourHistory-banner {
    height: 400px;
  }
  .nosotros-ourHistory-text {
    width: 100%;
  }
  .nosotros-mobilei-phone-x {
    display: none;
  }
  .nosotros-goals-banner {
    padding: var(--dl-space-space-unit);
  }
  .nosotros-goal-group {
    flex-direction: column;
  }
  .nosotros-goal-container {
    grid-template-columns: 1fr;
  }
  .nosotros-goal-image {
    width: 300px;
    height: 300px;
  }
  .nosotros-goal-image:first-child{
    order: 2;
  }
  .nosotros-values-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-steps-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .nosotros-step-card {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .nosotros-values-title {
    font-size: 2.5rem;
  }
  .nosotros-values-text {
    font-size: 1rem;
  }
  .nosotros-step-image {
    width: var(--dl-size-size-medium);
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .nosotros-step-broken-line {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0;
    border-left-width: 2px;
  }
  .nosotros-step-description{
    gap: 20px;
  }
  .nosotros-container-policy {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-text-policy {
    font-size: 2.5rem;
  }

}
@media(max-width: 394px) {
  .nosotros-banner {
    padding: var(--dl-space-space-halfunit);
  }
  .nosotros-ourHistory-banner {
    height: 500px;
  }
  .nosotros-ourHistory-title {
    font-size: 36px;
  }
  .nosotros-ourHistory-text {
    font-size: 16px;
  }
  .nosotros-goal-image {
    width: 250px;
    height: 250px;
  }
  .nosotros-goal-title {
    font-size: 36px;
  }
  .nosotros-goal-text {
    font-size: 16px;
  }
  .nosotros-values-banner {
    padding: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-twounits);
  }
  .nosotros-values-container {
    margin-bottom: var(--dl-space-space-unit);
    gap: 20px;
  }
  .nosotros-steps-container {
    padding-left: 0;
  }
  .nosotros-values-title {
    font-size: 2rem;
  }
  .nosotros-values-text {
    font-size: 1rem;
  }
  .nosotros-text-policy {
    font-size: 2rem;
  }

}
</style>
