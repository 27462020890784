<template>
  <header data-role="Header" class="header-header">
    <div class="header-social-bar">
      <div class="header-text">
        <i class="fas fa-phone"></i>
        <div>
          <span class="phone">{{ $t('headers.main-mobile.description') }}</span>
          <span class="phone">{{ $t('headers.main-phone.description') }}</span>
        </div>
      </div>
      <div class="header-social-container">
        <!-- linkedin -->
        <svg viewBox="0 0 24 24" class="social-icon">
          <a xlink:href="https://www.linkedin.com/company/acs-energy/" target="_blank">
            <path
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </a>
        </svg>
        <!-- twitter -->
        <svg viewBox="0 0 20 20" class="social-icon">
          <a xlink:href="https://twitter.com/AcsEnergysas" target="_blank">
            <path
              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
          </a>
        </svg>
        <!-- instagram -->
        <svg viewBox="0 0 24 24" class="social-icon">
          <a xlink:href="https://www.instagram.com/acsenergysas/" target="_blank">
            <!-- <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path> -->
            <path
              d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
          </a>
        </svg>
        <!-- facebook -->
        <!-- <svg viewBox="0 0 603.2582857142856 1024" class="social-icon">
          <a xlink:href="https://es-la.facebook.com/" target="_blank">
            <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
          </a>
        </svg> -->
        <!-- youtube -->
        <!-- <svg viewBox="0 0 1024 1024" class="social-icon">
          <a xlink:href="https://www.youtube.com/" target="_blank">
            <path d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z"></path>
          </a>
        </svg> -->
      </div>
      <!-- Menú de lenguaje -->
      <div class="header-lang-btn">
        <div class="lang-btn" v-on:click="setLanguaje()">
          <img :src="require('../assets/' + getLanguaje() + '.png')">
          <a class="lang-desc">{{ $t('lang.name') }}</a>
          <a class="lang-abbrev">{{ $t('lang.abbreviation') }}</a>
        </div>
      </div>
    </div>
    <div class="header-nav-container">
      <!-- Logo de la empresa -->
      <router-link to="/">
        <img src="../assets/img/logo/acslogo.png" class="header-logo" />
      </router-link>
      <!-- Barra de menú -->
      <link-menu></link-menu>
    </div>
  </header>
</template>

<script>
import LinkMenu from './link-menu'
import i18n from "@/international/i18n";
export default {
  name: 'Header',
  props: {},
  components: {
    LinkMenu,
  },
  methods: {
    setLanguaje() {
      const idioma = i18n.global.locale.value;
      if (idioma === 'es') {
        try {
          i18n.global.locale.value = 'en';
        } catch (error) {
          console.error('Error:', error);
        }
      }
      else {
        try {
          i18n.global.locale.value = 'es';
        } catch (error) {
          console.error('Error:', error);
        }
      }
    },
    getLanguaje() {
      return i18n.global.locale.value;
    }
  }
}
</script>

<style scoped>
.header-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 100%;
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding: var(--dl-space-space-twounits) var(--dl-space-space-threeunits);
  justify-content: space-between;
  background-color: #191919;
}

.header-social-bar {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 50px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
}

.header-text {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-text div span {
  margin-left: var(--dl-space-space-unit);
}

.header-social-container {
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  margin-left: auto;
}

.social-icon {
  fill: #ffffff;
  width: 18px;
  height: 18px;
  transition: all .4s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.5);
}

.social-icon a {
  fill: #ffffff;
}

.header-nav-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  flex-direction: row;
}

.header-logo {
  width: auto;
  height: var(--dl-size-size-medium);
  object-fit: fill;
}

.header-lang-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-lang-btn a {
  padding: 12px;
  text-decoration: none;
  font-weight: normal;
}

.header-lang-btn img {
  width: 32px;
  height: 32px;
}

.lang-btn {
  display: flex;
  padding-left: 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  height: 100%;
  align-items: center;
}

.lang-abbrev {
  display: none;
}


@media(max-width: 1440px) {
  .header-header {
    height: auto;
  }

  .header-social-bar {
    height: auto;
  }

  .header-nav-container {
    height: auto;
  }
}

@media(max-width: 1366px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .header-logo {
    width: auto;
    height: var(--dl-size-size-medium);
  }

  .header-text {
    font-size: 16px;
  }
}

@media(max-width: 991px) {
  .header-header {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .header-lang-btn img {
    width: 24px;
    height: 24px;
  }

  .lang-desc {
    display: none;
  }

  .lang-abbrev {
    display: flex;
    font-size: 12px;
  }

  .header-logo {
    height: var(--dl-size-size-xmedium);
    margin-left: var(--dl-space-space-unit);
  }
}

@media(max-width: 394px) {
  .header-header {
    min-width: 395px;
    padding: var(--dl-space-space-unit);
  }

  .header-social-bar {
    height: auto;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }

  .header-social-container{
    width: 30%;
  }

  .header-text {
    font-size: 12px;
  }

  .header-text div {
    display: flex;
    flex-direction: column;
  }

  .header-text div span {
    margin-left: var(--dl-space-space-halfunit);
  }
}
</style>
