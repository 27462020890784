<template>
  <div class="servicios-container">
    <app-header rootClassName="header-root-class-name1"></app-header>
    <div class="servicios-container01">
      <h1 class="servicios-text">{{ $t('headers.services.title') }}</h1>
    </div>
    <div class="servicios-container02">
      <div class="servicios-container03">
        <h1 class="servicios-text01">{{$t('services.section-one.title')}}</h1>
      </div>
      <div class="servicios-gallery">
        <div class="servicios-container04">
          <div class="servicios-container05">
            <h1 class="servicios-text05">{{$t('services.section-one.items.service-four')}}</h1>
            <span class="servicios-text03">
            </span>
          </div>
        </div>
        <div class="servicios-container06">
          <div class="servicios-container07">
            <h1 class="servicios-text05">{{$t('services.section-one.items.service-two')}}</h1>
          </div>
        </div>
        <div class="servicios-container08">
          <div class="servicios-container09">
            <h1 class="servicios-text05">{{$t('services.section-one.items.service-three')}}</h1>
            <span class="servicios-text07">
            </span>
          </div>
        </div>
        <div class="servicios-container10">
          <div class="servicios-container11">
            <h1 class="servicios-text05">{{$t('services.section-one.items.service-one')}}</h1>
            <span class="servicios-text10">
              </span>
          </div>
        </div>
      </div>
    </div>
    <div class="servicios-container12">
      <div class="servicios-container13">
        <h1 class="servicios-text40">{{$t('services.section-two.title')}}</h1>
      </div>
      <div class="servicios-gallery3">
        <div class="servicios-container41">
          <div class="servicios-container42">
            <h1 class="servicios-text41">{{$t('services.section-two.items.service-one')}}</h1>
            <span class="servicios-text42">
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="servicios-container14">
      <div class="servicios-container15">
        <h1 class="servicios-text21">{{$t('services.section-three.title')}}</h1>
      </div>
      <div class="servicios-gallery1">
        <div class="servicios-container16">
          <div class="servicios-container17">
            <h1 class="servicios-text22">{{$t('services.section-three.items.service-six')}}</h1>
            <span class="servicios-text23">
            </span>
          </div>
        </div>
        <div class="servicios-container18">
          <div class="servicios-container19">
            <h1 class="servicios-text25">{{$t('services.section-three.items.service-three')}}</h1>
            <span class="servicios-text26">
            </span>
          </div>
        </div>
        <div class="servicios-container20">
          <div class="servicios-container21">
            <h1 class="servicios-text28">{{$t('services.section-three.items.service-four')}}</h1>
            <span class="servicios-text29">
              </span>
          </div>
        </div>
        <div class="servicios-container22">
          <div class="servicios-container23">
            <h1 class="servicios-text31">{{$t('services.section-three.items.service-two')}}</h1>
            <span class="servicios-text32">
            </span>
          </div>
        </div>
        <div class="servicios-container24">
          <div class="servicios-container25">
            <h1 class="servicios-text34">{{$t('services.section-three.items.service-one')}}</h1>
            <span class="servicios-text35">
            </span>
          </div>
        </div>
        <div class="servicios-container26">
          <div class="servicios-container27">
            <h1 class="servicios-text37">{{$t('services.section-three.items.service-five')}}</h1>
            <span class="servicios-text38">
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="servicios-container28">
      <div class="servicios-container29"></div>
      <div class="servicios-container30">
        <h1 class="servicios-text40">{{$t('services.section-four.title')}}</h1>
      </div>
      <div class="servicios-gallery2">
        <div class="servicios-container31">
          <div class="servicios-container32">
            <h1 class="servicios-text44">{{$t('services.section-four.items.service-one')}}</h1>
            <span class="servicios-text42">
            </span>
          </div>
        </div>
        <div class="servicios-container33">
          <div class="servicios-container34">
            <h1 class="servicios-text44">{{$t('services.section-four.items.service-three')}}</h1>
          </div>
        </div>
        <div class="servicios-container35">
          <div class="servicios-container36">
            <h1 class="servicios-text44">{{$t('services.section-four.items.service-two')}}</h1>
            <span class="servicios-text46">
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import GalleryCard2 from '../components/gallery-card2'
import AppFooter from '../components/footer'

export default {
  name: 'Servicios',
  components: {
    AppHeader,
    GalleryCard2,
    AppFooter,
  },
  data() {
    return {
      rawqggf: ' ',
      rawm5ak: ' ',
      rawc3z5: ' ',
      rawamsv: ' ',
    }
  },
  metaInfo: {
    title: 'Servicios - AcsEnergy',
    meta: [
      {
        property: 'og:title',
        content: 'Servicios - AcsEnergy',
      },
    ],
  },
}
</script>

<style scoped>
.servicios-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.servicios-container01 {
  flex: 0 0 auto;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.servicios-text {
  align-self: center;
}
.servicios-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.servicios-container03 {
  flex: 1;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.servicios-text01 {
  align-self: center;
}
.servicios-gallery {
  width: 100%;
  height: 794px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a c d" "a c d";
  grid-template-columns: 1fr 1fr 1fr;
}
.servicios-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/5.jpg');
  background-position: center;
}
.servicios-container04:hover {
  transform: scale(0.98);
}
.servicios-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  min-height: 300px;
}
.servicios-text02 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.servicios-text03 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("../../public/external/1.jpg");
  background-position: center;
}
.servicios-container06:hover {
  transform: scale(0.98);
}
.servicios-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  min-height: 300px;
}
.servicios-text05 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-family: Arial;
  font-weight: 600;
}
.servicios-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/3.jpg');
  background-position: center;
}
.servicios-container08:hover {
  transform: scale(0.98);
}
.servicios-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  min-height: 300px;
}
.servicios-text06 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.servicios-text07 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/4.jpg');
  background-position: center;
}
.servicios-container10:hover {
  transform: scale(0.98);
}
.servicios-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  min-height: 300px;
}
.servicios-text09 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.servicios-text10 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.servicios-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.servicios-container15 {
  flex: 1;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}

.servicios-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  background-color: var(--dl-color-complementario-01);
  padding-bottom: var(--dl-space-space-twounits);
}
.servicios-container13 {
  flex: 1;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  margin-left:0px;
  padding-bottom: var(--dl-space-space-unit);
}
.servicios-text12 {
  align-self: center;
}
.servicios-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-threeunits);
  min-height: 80vh;
  align-items: center;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  //background-size: cover;
  background-repeat: round;
  justify-content: center;
  background-image: url('../../public/external/6.jpg');
}
.servicios-text13 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
}
.servicios-text14 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}

.servicios-text21 {
  align-self: center;
}
.servicios-gallery1 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.servicios-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/10.jpg');
  background-position: center;
}
.servicios-container16:hover {
  transform: scale(0.98);
}
.servicios-container17 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  min-height: 300px;
  flex-direction: column;
}
.servicios-text22 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text23 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/7.jpg');
  background-position: center;
}
.servicios-container18:hover {
  transform: scale(0.98);
}
.servicios-container19 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text25 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text26 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/9.jpg');
  background-position: center;
}
.servicios-container20:hover {
  transform: scale(0.98);
}
.servicios-container21 {
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text28 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text29 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/12.jpg');
  background-position: center;
}
.servicios-container22:hover {
  transform: scale(0.98);
}
.servicios-container23 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  min-height: 300px;
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text31 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text32 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/8.jpg');
  background-position: center;
}
.servicios-container24:hover {
  transform: scale(0.98);
}
.servicios-container25 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text34 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text35 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/11.jpg');
  background-position: center;
}
.servicios-container26:hover {
  transform: scale(0.98);
}
.servicios-container27 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text37 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text38 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 0px;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.servicios-container29 {
  top: 0px;
  left: 0px;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 628px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-logo-01);
}
.servicios-container30 {
  width: var(--dl-size-size-maxwidth);
  height: 121px;
  display: flex;
  z-index: 100;
  //align-items: center;
  flex-direction: row;
  padding: var(--dl-space-space-threeunits);
}
.servicios-text40 {
  color: var(--dl-color-gray-white);
  //padding-left: var(--dl-space-space-fiveunits);
}

.servicios-gallery2 {
  width: 100%;
  height: 577px;
  display: grid;
  z-index: 100;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  padding: var(--dl-space-space-twounits) var(--dl-space-space-threeunits);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "a b b" "a c c";
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
}
.servicios-gallery3 {
  width: 100%;
  height: 577px;
  display: grid;
  z-index: 100;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  grid-template-rows: 1fr;
  grid-template-areas: "a";
  grid-template-columns: 1fr;
}
.servicios-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/16.jpg');
  background-position: center;
}
.servicios-container31:hover {
  transform: scale(0.98);
}
.servicios-container41 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  opacity: 1;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
  background-size: cover;
  background-image: url('../../public/external/6.jpg');
  background-position: bottom;
}
.servicios-container41:hover {
  transform: scale(0.98);
}
.servicios-container42 {
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;

}
.servicios-container32 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text41 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  font-weight: 600;
}
.servicios-text42 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.servicios-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-image: url('../../public/external/14.jpg');
  background-position: center;
}
.servicios-container33:hover {
  transform: scale(0.98);
}
.servicios-container34 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.servicios-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('../../public/external/17.jpg');
  background-position: bottom;
}
.servicios-container35:hover {
  transform: scale(0.98);
}
.servicios-container36 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 300px;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-right: 19px;
  flex-direction: column;
}
.servicios-text44 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text45 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}
.servicios-text46 {
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-weight: 600;
}

@media(max-width: 1600px) {
  .servicios-container01 {
    width: auto;
  }
  .servicios-container02 {
    width: auto;
  }
  .servicios-container03 {
    width: var(--dl-size-size-maxwidth);
    align-self: center;
    align-items: center;
  }
  .servicios-text01 {
    width: var(--dl-size-size-maxwidth);
  }
  .servicios-container13 {
    width: var(--dl-size-size-maxwidth);

    margin-left: 150px;
  }
  .servicios-hero {
    width: 1306px;
  }
  .servicios-container15 {
    width: var(--dl-size-size-maxwidth);
    margin-left: 150px;
  }
  .servicios-container13 {
    width: var(--dl-size-size-maxwidth);
  }
  .servicios-container30 {
    width: var(--dl-size-size-maxwidth);
    margin-left: 150px;
  }
}
@media(max-width: 1200px) {
  .servicios-container03 {
    width: 100%;
  }
  .servicios-text01 {
    width: 100%;
  }
  .servicios-container13 {
    width: 100%;
    margin-left: var(--dl-space-space-fiveunits);
  }
  .servicios-hero {
    width: 100%;
  }
  .servicios-container15 {
    width: 100%;
  }
  .servicios-gallery1 {
    height: 876px;
    max-width: var(--dl-size-size-maxwidth);
  }
  .servicios-container28 {
    background-color: transparent;
  }
  .servicios-container30 {
    width: 100%;
  }
  .servicios-text40{
    margin-left: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 991px) {
  .servicios-container03 {
    width: 100%;
  }
  .servicios-gallery {
    grid-gap: var(--dl-space-space-unit);
  }
  .servicios-text03 {
    text-align: center;
  }
  .servicios-text05 {
    text-align: center;
  }
  .servicios-text07 {
    text-align: center;
  }
  .servicios-text10 {
    text-align: center;
  }
  .servicios-container15 {
    width: 100%;
  }
  .servicios-gallery1 {
    grid-gap: var(--dl-space-space-unit);
  }
  .servicios-container28 {
    background-color: transparent;
  }
  .servicios-container30 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .servicios-gallery {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .servicios-gallery1 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .servicios-gallery2 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .servicios-gallery3 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .servicios-container33 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .servicios-gallery {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .servicios-gallery1 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .servicios-gallery2 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .servicios-gallery3 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }

  .servicios-container12{
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: var(--dl-space-space-unit);
  }
}
</style>

