import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'es',
    messages: {
        'en': {},
        'es': require('../locales/es.json')
      }
})
export default i18n
