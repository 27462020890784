import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./style.css";
import VueParticles from "vue-particles";
import i18n from "@/international/i18n";
import Carousel from "primevue/carousel";
import Divider from 'primevue/divider';
import "./Icons.css";
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/saga-green/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'
import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import PrimeVue from "primevue/config";

createApp(App)
    .use(router)
    .use(VueParticles)
    .use(i18n)
    .use(PrimeVue)
    .component('Carousel', Carousel)
    .component('Button', Button)
    .component('TabView',TabView)
    .component("Toast",Toast)
    .component('TabPanel', TabPanel)
    .component('InputText', InputText)
    .component("Divider", Divider)
    .use(ToastService)
    .mount('#app')


