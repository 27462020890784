
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import Nosotros from "@/views/nosotros.vue";
import Servicios from "@/views/servicios.vue";
import Contactenos from "@/views/contactenos.vue";
import Home from "@/views/home.vue";

const routes = [
    {
        name: 'Nosotros',
        path: '/nosotros',
        component: Nosotros,
    },
    {
        name: 'Servicios',
        path: '/servicios',
        component: Servicios,
    },
    {
        name: 'Home',
        path: '/',
        component: Home,
    },
    {
        name: 'Contactenos',
        path: '/contacto',
        component: Contactenos,
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
