<template>
    <router-view/>
</template>

<script>
import {loadTranslations} from "@/services/i18nService";
import i18n from "@/international/i18n";

export default {
  name: "App",
  async beforeMount() {
    try {
      const translationsEs = await loadTranslations('es');
      i18n.global.setLocaleMessage('es', translationsEs);
      const translationsEn = await loadTranslations('en');
      i18n.global.setLocaleMessage('en', translationsEn);
    } catch (error) {
      console.error('Error:', error);
    }
  },
}
</script>