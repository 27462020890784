<template>
  <footer class="footer-footer">
    <div class="footer-container">
      <div class="footer-logo">
        <img :alt="image_alt" src="../assets/img/logo/acslogo_footer.png" class="footer-image" />
        <span class="footer-slogan">{{ $t('headers.slogan.description') }}</span>
      </div>
      <div class="footer-links-container">
        <div class="footer-contact">
          <span class="footer-title">{{ $t('headers.footer-title.description') }}</span>
          <span class="footer-text"><i class="fas fa-envelope"></i> {{ $t('headers.main-email-sin.description').replace("quot","@") }}</span>
          <span class="footer-text"><i class="fas fa-phone"></i> {{ $t('headers.main-mobile.description') }}  {{ $t('headers.main-phone.description') }}</span>
          <span class="footer-text"><i class="fas fa-thumbtack"></i> {{ $t('headers.main-address.description') }}</span>
        </div>
      </div>
    </div>
    <div class="footer-separator"></div>
    <span class="footer-rights-reserved">{{ text }}</span>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    text1: {
      type: String,
      default: 'Estamos a tu alcance\n',
    },
    text4: {
      type: String,
      default: '(+57) 313 835 4803 (+57) 601 797 33 8',
    },
    rootClassName: String,
    text: {
      type: String,
      default: '© 2023 Digital Codex S.A.S, All Rights Reserved.',
    },
    image_alt: {
      type: String,
      default: 'logo',
    },
    text2: {
      type: String,
      default: 'Contactanos',
    },
    image_src: {
      type: String,
      default: '../../public/external/24.jpg',
    },
    text3: {
      type: String,
      default: 'servicioalcliente@acsenergy.com\n',
    },

    text5: {
      type: String,
      default: 'Avenida Carrera 50 # 1 -54 Bogotá\n',
    },
  },
}
</script>

<style scoped>
.footer-footer {
  color: var(--dl-color-logo-blanco-02);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  padding: var(--dl-space-space-twounits) var(--dl-space-space-threeunits);
  background-size: cover;
  justify-content: space-between;
  background-image: url("../../public/external/24.jpg");
  background-position: bottom;

}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: var(--dl-space-space-unit);
}

.footer-image {
  width: auto;
  height: var(--dl-size-size-medium);
}

.footer-links-container {
  display: flex;
  padding: var(--dl-space-space-unit);
}

.footer-contact {
  display: flex;
  height: auto;
  align-items: flex-start;
  flex-direction: column;
}

.footer-slogan {
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  margin-top: var(--dl-space-space-unit);
  color: var(--dl-color-logo-blanco-01);
}

.footer-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.footer-text {
  font-size: 20px;
  width: 100%;
  margin-bottom: var(--dl-space-space-unit);
  padding: 0 var(--dl-space-space-unit);
}

.footer-text i {
  margin-right: var(--dl-space-space-unit);
}

.footer-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}

.footer-rights-reserved {
  font-size: 10px;
  align-self: center;
}

@media(max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }

  .footer-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-logo {
    align-items: center;
  }

  .footer-text {
    font-size: 14px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .footer-links-container {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
}

@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }

  .footer-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .footer-logo {
    align-self: center;
    margin-left: 0;
  }

  .footer-image {
    margin-bottom: 0;
  }

  .footer-text {
    padding: 0;
  }

  .footer-text i {
    margin-right: var(--dl-space-space-halfunit);
  }


  .footer-links-container {
    align-items: center;
    flex-direction: column;
  }

  .footer-contact {
    align-items: flex-start;
  }

  .footer-rights-reserved {
    text-align: center;
  }
}</style>
