<template>
  <div class="page-container">
    <div class="page-back-panel"></div>
      <div class="page-stay-panel">
        <h1 class="page-stay-title">
          <span>{{ $t('headers.we-have.title') }}</span>
          <br />
        </h1>
        <span class="page-stay-description">
          {{ $t('headers.we-have.description') }}
        </span>
        <div class="page-panel-we-have">
          <a class="page-feature-card have-one" :href="$t('we-have-item.we-have-one.url')" target="_blank">
            <h2 class="page-title">{{ $t('we-have-item.we-have-one.title')}}</h2>
            <span class="page-description">
              {{ $t('we-have-item.we-have-one.description')}}
            </span>
          </a>
          <a class="page-feature-card have-two" :href="$t('we-have-item.we-have-two.url')" target="_blank">
            <h2 class="page-title1"> {{ $t('we-have-item.we-have-two.title')}}</h2>
            <span class="page-description1">
               {{ $t('we-have-item.we-have-two.description')}}
            </span>
          </a>
          <a class="page-feature-card have-three" :href="$t('we-have-item.we-have-three.url')" target="_blank">
            <h2 class="page-title2"> {{ $t('we-have-item.we-have-three.title')}}</h2>
            <span class="page-description2">
               {{ $t('we-have-item.we-have-three.description')}}
            </span>
          </a>
          <a class="page-feature-card have-four" :href="$t('we-have-item.we-have-four.url')" target="_blank">
            <h2 class="page-title3"> {{ $t('we-have-item.we-have-four.title')}}</h2>
            <span class="page-description3">
               {{ $t('we-have-item.we-have-four.description')}}
            </span>
          </a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'WeHave',
  props: {},
}
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}
.page-back-panel {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 400px;
  display: flex;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-logo-03);
}
.page-stay-panel {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-self: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}
.page-stay-title {
  font-size: 3rem;
  color: var(--dl-color-logo-blanco-03);
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
}
.page-stay-description {
  color: var(--dl-color-logo-blanco-03);
  width: 70%;
  font-size: 1.15rem;
  align-self: flex-start;
  text-align: justify;
}
.page-panel-we-have {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.have-one:hover {
  transform: scale(1.03);
}
.have-two:hover {
  transform: scale(1.03);
}
.have-three:hover {
  transform: scale(1.03);
}
.have-four:hover {
  transform: scale(1.03);
}
.page-feature-card {
  width: 90%;
  height: 255px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("../../public/tarjeta.png");
  background-position: center;
}
.page-title {
  font-size: 1.4rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.page-description {
  color: var(--dl-color-gray-700);
  width: 90%;
  font-size: 1rem;
  text-align: center;
}
.page-title1 {
  font-size: 1.4rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.page-description1 {
  width: 90%;
  color: var(--dl-color-gray-700);
  font-size: 1rem;
  text-align: center;
}
.page-title2 {
  font-size: 1.4rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.page-description2 {
  width: 90%;
  color: var(--dl-color-gray-700);
  font-size: 1rem;
  text-align: center;
}
.page-title3 {
  font-size: 1.4rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.page-description3 {
  width: 90%;
  color: var(--dl-color-gray-700);
  font-size: 1rem;
  text-align: center;
}
@media(max-width: 1440px) {
  .page-back-panel {
    max-width: 100%;
    align-self: center;
  }
  .page-stay-panel {
    max-width: var(--dl-size-size-default);
    align-self: center;
  }
  .page-feature-card {
    height: 285px;
  }
  .page-title {
    font-size: 1.4rem;
  }
  .page-description {
    font-size: 1.1rem;
  }
  .page-feature-card1 {
    height: 285px;
  }
  .page-title1 {
    font-size: 1.4rem;
  }
  .page-description1 {
    font-size: 1.1rem;
  }
  .page-feature-card2 {
    height: 285px;
  }
  .page-title2 {
    font-size: 1.4rem;
  }
  .page-description2 {
    font-size: 1.1rem;
  }
  .page-feature-card3 {
    height: 285px;
  }
  .page-title3 {
    font-size: 1.4rem;
  }
  .page-description3 {
    font-size: 1.1rem;
  }
}
@media(max-width: 1366px) {
  .page-back-panel {
    max-width: 100%;
  }
  .page-stay-panel {
    padding: var(--dl-space-space-unit);
    max-width: 100%;
  }
  .page-stay-title {
    margin-top: var(--dl-space-space-twounits);
    margin-left: var(--dl-space-space-twounits);
  }
  .page-stay-description {
    margin-left: var(--dl-space-space-twounits);
  }
  .page-feature-card {
    width: 100%;
    height: 345px;
    padding: 0px;
  }
  .page-title {
    font-size: 1.5rem;
  }
  .page-description {
    font-size: 1.1rem;
  }
  .page-feature-card1 {
    width: 90%;
    height: 285px;
    padding: 0px;
  }
  .page-title1 {
    font-size: 1.5rem;
  }
  .page-description1 {
    font-size: 1.1rem;
  }
  .page-feature-card2 {
    width: 90%;
    height: 285px;
    padding: 0px;
  }
  .page-title2 {
    font-size: 1.5rem;
  }
  .page-description2 {
    font-size: 1.1rem;
  }
  .page-title3 {
    font-size: 1.5rem;
  }
  .page-description3 {
    font-size: 1.1rem;
  }
}@media(max-width: 1280px) {
  .page-feature-card {
    height: 320px;
  }
}
@media(max-width: 991px) {
  .page-back-panel {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .page-stay-panel {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .page-stay-title {
    text-align: center;
  }
  .page-stay-description {
    width: 70%;
    text-align: left;
  }
  .page-panel-we-have {
    grid-template-columns: 1fr 1fr;
    flex: 1;
    align-self: center;
    height: auto;
  }
  .page-feature-card {
    height: 490px;
  }
  .page-title {
    font-size: 1.8rem;
  }
  .page-description {
    font-size: 1.3rem;
  }
  .page-feature-card1 {
    height: 440px;
  }
  .page-title1 {
    font-size: 1.8rem;
  }
  .page-description1 {
    font-size: 1.3rem;
  }
  .page-feature-card2 {
    height: 440px;
  }
  .page-title2 {
    font-size: 1.8rem;
  }
  .page-description2 {
    font-size: 1.3rem;
  }
  .page-feature-card3 {
    height: 440px;
  }
  .page-title3 {
    font-size: 1.8rem;
  }
  .page-description3 {
    font-size: 1.3rem;
  }
}
@media(max-width: 720px) {
  .page-feature-card {
    width: 100%;
    height: 360px;
    padding: 0px;
    margin: 0 0 var(--dl-space-space-twounits);
  }
  .page-stay-title {
    margin-left: 0px;
  }
  .page-stay-description {
    width: 100%;
    margin-left: 0px;
  }
  .page-title {
    font-size: 1.6rem;
  }
  .page-description {
    font-size: 1rem;
  }
  .page-title1 {
    font-size: 1.6rem;
  }
  .page-description1 {
    font-size: 1rem;
  }
  .page-title2 {
    font-size: 1.6rem;
  }
  .page-description2 {
    font-size: 1rem;
  }
  .page-title3 {
    font-size: 1.6rem;
  }
  .page-description3 {
    font-size: 1rem;
  }
}
@media(max-width: 479px) {
  .page-panel-we-have {
    display: inline;
  }
  .page-feature-card {
    width: 100%;
    height: 450px;
    padding: 0px;
    margin: 0 0 var(--dl-space-space-twounits);
  }
}
@media(max-width: 394px) {

  .page-back-panel {
    height: 416px;
  }
  .page-stay-description {
    width: 100%;
    text-align: center;
  }
  .page-panel-we-have {
    display: inline;
  }
  .page-feature-card {
    width: 100%;
    height: 360px;
    padding: 0px;
    margin: 0 0 var(--dl-space-space-twounits);
  }
  .page-description {
    font-size: 1rem;
  }
}
</style>