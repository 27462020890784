<template>
  <div class="polices-card">
    <div class="polices-card-container">
      <h1 class="polices-card-title">{{ title }}</h1>
      <span class="polices-card-description">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicieCard',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    description: {
      type: String,
      default:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
    },
  },
}
</script>

<style scoped>
.polices-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 5px rgba(18, 18, 18, 0.1);
  border-radius: 25px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--dl-space-space-twounits);
}
.polices-card-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.polices-card-title {
  color: var(--dl-color-gray-500);
  font-size: 1.7rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.polices-card-description {
  color: var(--dl-color-gray-500);
  font-size: 1.2rem;
  text-align: justify;
  line-height: 32px;
}

@media(max-width: 1440px) {
  .polices-card-title {
    font-size: 1.5rem;
  }
  .polices-card-description {
    font-size: 1rem;
    line-height: 28px;
  }
}
@media(max-width: 1280px) {
  .polices-card-description {
    font-size: 1rem;
    line-height: 22px;
  }
}
@media(max-width: 991px) {

}
@media(max-width: 720px) {
  .polices-card {
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .polices-card-container {
    align-items: center;
    margin-right: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .polices-card-title {
    text-align: left;
  }
  .polices-card-description {
    text-align: justify;
    line-height: 20px;
  }
}

@media(max-width: 394px) {
  .polices-card-title {
    font-size: 1.2rem;
  }
}
</style>