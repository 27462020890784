<template>
  <div class="home-container">
    <app-header rootClassName="header-root-class-name"></app-header>
    <div class="home-hero">
      <div class="home-container1">
        <h1 class="home-text">ACS ENERGY</h1>
        <br>
        <span class="home-text01 typed-text loop-text">{{ typeValue }}</span>
        <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
        <span class="overlay_effect"></span>
      </div>
    </div>
    <WeHave/>
    <div class="home-features">
      <h1 class="home-text06">
        {{ $t('home.exploreService') }}
        <br/>
      </h1>
      <span class="home-text09">
        {{ $t('home.exploreDescription') }}
      </span>
      <div class="home-container5">
        <ver-servicios rootClassName="rootClassName" :title='$t("services.section-four.title")'></ver-servicios>
        <ver-servicios rootClassName="rootClassName3" :title='$t("services.section-one.title")'></ver-servicios>
        <ver-servicios rootClassName="rootClassName5" :title='$t("services.section-three.title")'></ver-servicios>
        <ver-servicios rootClassName="rootClassName1" :title='$t("services.section-two.title")'></ver-servicios>
      </div>
    </div>
    <div class="home-container6">
      <img alt="image" src="/external/27.png" class="home-image2"/>
      <img alt="image" src="/external/25.png" class="home-image"/>
      <img alt="image" src="/external/26.png" class="home-image1"/>
    </div>
    <app-footer rootClassName="footer-root-class-name1"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import VerServicios from '../components/ver-servicios'
import AppFooter from '../components/footer'
import WeHave from "@/components/WeHave.vue";

export default {
  name: 'Home',
  components: {
    AppHeader,
    WeHave,
    VerServicios,
    AppFooter,
  },
  metaInfo: {
    title: 'AcsEnergy',
    meta: [
      {
        property: 'og:title',
        content: 'AcsEnergy',
      },
    ],
  },
  data () {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: [this.$t('home.bannerOne'), this.$t('home.bannerTwo'), this.$t('home.bannerFix')],
      typingSpeed: 80,
      erasingSpeed: 50,
      newTextDelay: 3000,
      typeArrayIndex: 0,
      charIndex: 0,
    }
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;

        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
            this.charIndex
        );
        this.charIndex += 1;

        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;

        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
            0,
            this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;

        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
  created() {
    setTimeout(this.typeText, 2000);
  },
  updated() {
    this.typeArray= [this.$t('home.bannerOne'), this.$t('home.bannerTwo'), this.$t('home.bannerFix')]
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-hero {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: 100%;
  align-self: center;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../public/external/23.jpg");
  background-repeat: round;
}

.home-container1 {
  display: flex;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
}

.home-text {
  color: white;
  width: 679px;
  font-size: 5rem;
  max-width: 25rem;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.home-text01 {
  color: white;
  width: 100%;
  text-align: left;
  font-size: 3rem;
}

.home-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 1;
  max-width: var(--dl-size-size-default);
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.home-text06 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text09 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
}

.home-container5 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.home-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-logo-03)
}

.home-image {
  width: 250px;
  height: 60px;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
  object-fit: fill;
}

.home-image1 {
  width: 300px;
  height: 120px;
  align-self: center;
  object-fit: contain;
  margin-left: var(--dl-space-space-halfunit);
  background-repeat: round;
}

.home-image2 {
  width: 300px;
  height: 120px;
  margin-left: var(--dl-space-space-halfunit);
  align-self: center;
  object-fit: contain;
}

@media (max-width: 1600px) {
  .home-text05 {
    text-align: justify;
  }

  .home-features {
    align-self: center;
  }

}

@media (max-width: 1366px) {
  .home1-we-have {
    max-width: 100%;
  }

  .home1-main-we-have {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    flex-wrap: wrap;
    max-width: 100%;
    align-items: center;
    margin-right: 0px;
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .home-text05 {
    text-align: justify;
  }
}

@media (max-width: 991px) {
  .home-image {
    width: 200px;
    height: 60px;
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
    object-fit: fill;
  }

  .home-image1 {
    width: 200px;
    height: 120px;
    align-self: center;
    object-fit: contain;
    margin-left: var(--dl-space-space-halfunit);
    background-repeat: round;
  }

  .home-image2 {
    width: 200px;
    height: 120px;
    margin-left: var(--dl-space-space-halfunit);
    align-self: center;
    object-fit: contain;
  }
  .home1-main-we-have {
    width: auto;
    height: auto;
    flex-wrap: wrap;
    max-width: 1400px;
    align-items: center;
    justify-content: space-between;

  }

  .home-container3 {
    flex-wrap: wrap;
  }

  .home-text02 {
    text-align: center;
  }

  .home-text05 {
    text-align: justify;
  }

  .home-container4 {
    flex-wrap: wrap;
  }

  .home-text06 {
    text-align: center;
  }

  .home-text09 {
    text-align: center;
  }

  .home-container5 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {

  .home1-main-we-have {
    gap: 0;
    flex-wrap: wrap;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    margin-top: 200px;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }

  .home-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-text {
    text-align: center;
  }

  .home-text01 {
    max-width: 100%;
    text-align: left;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }

  .home-container3 {
    flex-flow: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-text02 {
    text-align: center;
  }

  .home-text05 {
    text-align: justify;
  }

  .home-container4 {
    flex-flow: column;
    margin-top: 180px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-text06 {
    text-align: center;
  }

  .home-pricing {
    flex-direction: column;
  }
}

@media (max-width: 479px) {

  .home1-main-we-have {
    margin-top: 280px;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
  }

  .home-container1 {
    width: 100%;
  }

  .home-text {
    text-align: left;
  }

  .home-text01 {
    width: 100%;
    font-size: 2rem;
  }

  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-container5 {
    grid-template-columns: 1fr;
  }

  .home-container6 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-image {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-small);
  }

  .home-image1 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-large);
  }

  .home-image2 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-xmedium);
  }

}
</style>
