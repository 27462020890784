<template>
  <div class="header-nav">
    <input type="checkbox" id="burger-menu" />
    <label for="burger-menu" class="burger-menu">
      <svg viewBox="0 0 1024 1024" class="burger-menu-icon">
        <path
          d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z">
        </path>
      </svg>
    </label>
    <label for="burger-menu" class="burger-menu-close"></label>
    <nav class="link-menu-nav">
      <div>
        <router-link to="/" class="link-menu-navlink" v-on:click="checkUncheck()">{{ $t('menu-options.home') }}</router-link>
      </div>
      <div>
        <router-link to="/servicios" class="link-menu-navlink" v-on:click="checkUncheck()">{{ $t('menu-options.services') }}</router-link>
      </div>
      <div>
        <router-link to="/nosotros" class="link-menu-navlink" v-on:click="checkUncheck()">{{ $t('menu-options.about') }}</router-link>
      </div>
      <div>
        <router-link to="/contacto" class="link-menu-navlink" v-on:click="checkUncheck()">{{ $t('menu-options.contact') }}</router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'LinkMenu',
  props: {},
  methods: {
    checkUncheck() {
      var checkbox = document.getElementById('burger-menu');
      checkbox.checked = false;
    },
  }
}
</script>

<style scoped>
.header-nav {
  width: 100%;
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
}

.link-menu-nav,
div {
  display: flex;
  font-size: 2rem;
  align-items: center;
  flex-direction: row;
}

.link-menu-navlink {
  color: #ffffff;
  margin-left: var(--dl-space-space-threeunits);
  text-decoration: none;
  transition: all .4s ease-in-out;
}

.link-menu-navlink:hover,
.link-menu-navlink.router-link-exact-active {
  transform: scale(1.2);
  color: var(--dl-color-logo-03)
}

.burger-menu-icon,
input[type="checkbox"] {
  visibility: hidden;
}

@media(max-width: 1440px) {
  .link-menu-navlink {
    font-size: 1.7rem;
    margin-left: var(--dl-space-space-threeunits);
  }
}

@media(max-width: 1366px) {
  .link-menu-navlink {
    font-size: 1.5rem;
    margin-left: var(--dl-space-space-threeunits);
  }
}

@media (max-width: 991px) {
  .link-menu-navlink {
    font-size: 1.2rem;
    margin-left: var(--dl-space-space-twounits);
  }
}

@media(max-width: 720px) {

  .link-menu-nav {
    position: absolute;
    top: var(--dl-space-space-halfunit);
    right: 0;
    left: auto;
    display: flex;
    flex-direction: column;
    z-index: 2;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  .burger-menu {
    flex-direction: row;
    margin-left: auto;
  }

  .burger-menu-icon {
    fill: #ffffff;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    visibility: visible;
    z-index: 2;
    cursor: pointer;
    display: block;
  }

  div {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link-menu-navlink {
    width: 100%;
    text-align: center;
    margin: auto;
    visibility: hidden;
  }

  .link-menu-navlink:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .burger-menu-close {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  input#burger-menu:checked~.link-menu-nav,
  input#burger-menu:checked~.burger-menu-close {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  input#burger-menu:checked~.link-menu-nav {
    width: 50%;
    background: #000;
    box-shadow: 3px 3px 10px #ccc;
  }

  input#burger-menu:checked~.link-menu-nav div .link-menu-navlink {
    visibility: visible;
  }

  div .link-menu-navlink:hover,
  div .link-menu-navlink.router-link-exact-active {
    transform: scale(1);
  }
}
@media(max-width: 394px) {
  input#burger-menu:checked~.link-menu-nav {
    width: 70%;
  }
}
</style>
